import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const TalkRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/talk/cdr'
    },
    {
        path: 'cdr',
        component: wrapRuntimeImport(() => import('./screens/cdr.vue')),
        meta: makePermissionMeta('talk')
    },
    {
        path: 'fax',
        component: wrapRuntimeImport(() => import('./screens/fax.vue')),
        meta: makePermissionMeta('talk')
    },
    {
        path: 'softphone',
        component: wrapRuntimeImport(() => import('./screens/softphone.vue'))
    }
];
